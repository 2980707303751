import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Typography, Button, notification,message } from 'antd';
import { MailOutlined,CopyOutlined } from '@ant-design/icons';

const { Column } = Table;

function Evaluatoredit({ selectedEvaluatee }) {
  const [evaluators, setEvaluators] = useState([]);
  const [changedEvaluators, setChangedEvaluators] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedEvaluatee) {
      getEvaluators(selectedEvaluatee);
    }
  }, [selectedEvaluatee]);

  async function getEvaluators(selectedEvaluatee) {
    try {
      const response = await axios.get(`https://api-back.greatmanagers.org/evaluatorsi/${selectedEvaluatee}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    });
      const evaluatorsWithOldEmail = response.data.map(evaluator => ({
        ...evaluator,
        OldEvaluatorEmail: evaluator.EvaluatorEmail
      }));
      setEvaluators(evaluatorsWithOldEmail);
    } catch (error) {
      console.error('Error fetching evaluators:', error);
    }
  }

  const handleEmailChange = (index, e) => {
    const updatedEvaluators = [...evaluators];
    updatedEvaluators[index].EvaluatorEmail = e.target.value;
    setEvaluators(updatedEvaluators);

    // Track changed evaluator
    const changedEvaluator = {
      ...updatedEvaluators[index],
      NewEvaluatorEmail: e.target.value
    };
    if (!changedEvaluators.some(e => e.EvaluatorID === changedEvaluator.EvaluatorID)) {
      setChangedEvaluators([...changedEvaluators, changedEvaluator]);
    } else {
      const updatedChangedEvaluators = changedEvaluators.map(e => {
        if (e.EvaluatorID === changedEvaluator.EvaluatorID) {
          return changedEvaluator;
        }
        return e;
      });
      setChangedEvaluators(updatedChangedEvaluators);
    }
  };

  const handleSaveEmails = () => {
    setLoading(true);
    console.log('Saving evaluators:', changedEvaluators);
  
    axios.post('https://api-back.greatmanagers.org/updateevaluatorsz', changedEvaluators, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        // Add any other headers here if needed
      }
    })
    .then(response => {
      console.log('Evaluators updated successfully');
      message.success('Evaluators updated successfully!');
      setChangedEvaluators([]);
    })
    .catch(error => {
      console.error('Error updating evaluators:', error);
      notification.error('There was an error updating the evaluators. Please try again.');
    })
    .finally(() => {
      setLoading(false);
    });
  };
  

  return (
    <div>
      {selectedEvaluatee && (
        <div style={{ marginTop: '20px' }}>
          <Table dataSource={evaluators} rowKey="EvaluatorID"
          components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}>
            <Column
              title="Evaluator Email"
              dataIndex="EvaluatorEmail"
              key="EvaluatorEmail"
              render={(text, record, index) => (
                <Input
                  value={text}
                  onChange={(e) => handleEmailChange(index, e)}
                  prefix={<MailOutlined />}
                />
              )}
            />
            <Column
              title="Action Control"
              key="actions"
              render={(text, record, index) => (
                <Button
                  type="primary"
                  onClick={handleSaveEmails}
                  loading={loading} 
                  icon={<CopyOutlined 
                    style={{color: '#6D6D6D'}}
                  />}
                  style={
                    {
                      backgroundColor: '#ffffff',
                      borderColor: '#B5B5B5',
                    }
                  }
                >
                  <p style={{
                    color: '#6D6D6D',
                  }}>Save Email </p>
                </Button>
              )}
            />
          </Table>
        </div>
      )}
    </div>
  );
}

export default Evaluatoredit;
