import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Button, Space, Typography, Switch, Select, Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const { Option } = Select;

const Questions = () => {
  const [questions, setQuestions] = useState([]);
  const [editedQuestion, setEditedQuestion] = useState({ id: '', text: '', category: '', language: '', type: '', surveyType: '' });
  const [newQuestionText, setNewQuestionText] = useState('');
  const [newQuestionCategory, setNewQuestionCategory] = useState('');
  const [newQuestionLanguage, setNewQuestionLanguage] = useState('');
  const [newQuestionType, setNewQuestionType] = useState('');
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('ENG');
  const [surveyType, setSurveyType] = useState('');

  useEffect(() => {
    fetchQuestions();
  }, [language]);

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const url = language === 'ENG' ? 'https://api-back.greatmanagers.org/questionlist' : 'https://api-back.greatmanagers.org/questionlistsin';
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record) => {
    console.log('Editing Record:', record); // Log the record being edited
    setEditedQuestion({
      id: record.id,
      text: record.text,
      category: record.category,
      language: record.language,
      type: record.type,
      surveyType: record.surveyType,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      console.log('Payload for PUT Request:', {
        questionsId: editedQuestion.id,
        newText: editedQuestion.text,
        newCategory: editedQuestion.category,
        newLanguage: editedQuestion.language,
        newType: editedQuestion.type,
        newSurveyType: editedQuestion.surveyType,
      });

      await axios.post(`https://api-back.greatmanagers.org/questionsz/${editedQuestion.id}`, {
        newText: editedQuestion.text,
        newCategory: editedQuestion.category,
        newLanguage: editedQuestion.language,
        newType: editedQuestion.type,
        newSurveyType: editedQuestion.surveyType,
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      setEditedQuestion({ id: '', text: '', category: '', language: '', type: '', surveyType: '' });
      fetchQuestions();
    } catch (error) {
      console.error('Error updating question:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (questionId) => {
    setLoading(true);
    try {
      await axios.delete(`https://api-back.greatmanagers.org/questions/${questionId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      fetchQuestions();
    } catch (error) {
      console.error('Error deleting question:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (value, key) => {
    setEditedQuestion({ ...editedQuestion, [key]: value });
  };

  const handleNewQuestionTextChange = (e) => {
    setNewQuestionText(e.target.value);
  };

  const handleNewQuestionCategoryChange = (e) => {
    setNewQuestionCategory(e.target.value);
  };

  const handleNewQuestionLanguageChange = (value) => {
    setNewQuestionLanguage(value);
  };

  const handleNewQuestionTypeChange = (value) => {
    setNewQuestionType(value);
  };

  const handleNewQuestionSurveyTypeChange = (value) => {
    setSurveyType(value);
  };

  const handleAddQuestion = async () => {
    setLoading(true);
    if (!newQuestionText || !newQuestionCategory || !newQuestionLanguage || !newQuestionType || !surveyType) {
      console.error('All fields are required');
      alert('Please fill in all fields');
      setLoading(false);
      return;
    }

    try {
      await axios.post('https://api-back.greatmanagers.org/questions', {
        newText: newQuestionText,
        newCategory: newQuestionCategory,
        newLanguage: newQuestionLanguage,
        newType: newQuestionType,
        newSurveyType: surveyType,
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      setNewQuestionText('');
      setNewQuestionCategory('');
      setNewQuestionLanguage('');
      setNewQuestionType('');
      setSurveyType('');
      fetchQuestions();
    } catch (error) {
      console.error('Error adding question:', error);
      alert('Error adding question: ' + (error.response?.data?.message || 'Unknown error'));
    } finally {
      setLoading(false);
    }
  };

  const newQuestionForm = (
    <div style={{
      marginBottom: '20px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      borderRadius: '10px',
      paddingLeft: '30px',
      paddingTop: '10px',
      paddingBottom: '30px',
    }}>
      <Row gutter={16}>
        <Col span={10}>
          <Title level={5}>Question Content:</Title>
        </Col>
        <Col span={3}>
          <Title level={5}>Question Category:</Title>
        </Col>
        <Col span={3}>
          <Title level={5}>Question Language:</Title>
        </Col>
        <Col span={2}>
          <Title level={5}>Survey Type:</Title>
        </Col>
        <Col span={3}>
          <Title level={5}>Question Type:</Title>
        </Col>
        <Col span={3}>
          <Title level={5}>Action Controls:</Title>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={10}>
          <Input
            value={newQuestionText}
            onChange={handleNewQuestionTextChange}
            placeholder="Enter Question Text"
            style={{
              backgroundColor: '#F6F7FB',
              borderRadius: '10px',
              border: '1px solid #D9D9D9',
              minHeight: '32px',
              fontWeight: 'bold',
            }}
          />
        </Col>
        <Col span={3}>
          <Input
            value={newQuestionCategory}
            onChange={handleNewQuestionCategoryChange}
            placeholder="Enter Category"
            style={{
              backgroundColor: '#F6F7FB',
              borderRadius: '10px',
              border: '1px solid #D9D9D9',
              minHeight: '32px',
              fontWeight: 'bold',
            }}
          />
        </Col>
        <Col span={3}>
          <Select
            value={newQuestionLanguage}
            onChange={handleNewQuestionLanguageChange}
            placeholder="Select Language"
            style={{
              width: '100%',
              backgroundColor: '#F6F7FB',
              borderRadius: '10px',
              minHeight: '32px',
              fontWeight: 'bold',
            }}
          >
            <Option value="English">English</Option>
            <Option value="Sinhala">Sinhala</Option>
          </Select>
        </Col>
        <Col span={2}>
          <Select
            value={surveyType}
            onChange={handleNewQuestionSurveyTypeChange}
            placeholder="Survey Type"
            style={{
              width: '100%',
              backgroundColor: '#F6F7FB',
              borderRadius: '10px',
              minHeight: '32px',
              fontWeight: 'bold',
            }}
          >
            <Option value="CLA">CLA</Option>
            <Option value="GMA">GMA</Option>
            <Option value="ALA">ALA</Option>
          </Select>
        </Col>
        <Col span={3}>
          <Select
            value={newQuestionType}
            onChange={handleNewQuestionTypeChange}
            placeholder="Question Type"
            style={{
              width: '100%',
              backgroundColor: '#F6F7FB',
              borderRadius: '10px',
              minHeight: '32px',
              fontWeight: 'bold',
            }}
          >
            <Option value="MULTIPLE_CHOICE">Multiple Choice</Option>
            <Option value="TEXT">Text</Option>
          </Select>
        </Col>
        <Col span={2} style={{ textAlign: 'right' }}>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddQuestion}
              loading={loading}
              style={{
                backgroundColor: '#3B7DDE',
              }}
            >
              Add
            </Button>
            <Button
              icon={<FormOutlined />}
              onClick={() => {
                setNewQuestionText('');
                setNewQuestionCategory('');
                setNewQuestionLanguage('');
                setNewQuestionType('');
                setSurveyType('');
              }}
            >
              Clear
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );

  const columns = [
    {
      title: 'Question Content',
      dataIndex: 'text',
      key: 'text',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Input
              value={editedQuestion.text}
              onChange={(e) => handleInputChange(e.target.value, 'text')}
            />
          );
        }
        return text;
      },
    },
    {
      title: 'Question Category',
      dataIndex: 'category',
      key: 'category',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Input
              value={editedQuestion.category}
              onChange={(e) => handleInputChange(e.target.value, 'category')}
            />
          );
        }
        return text;
      },
    },
    {
      title: 'Question Language',
      dataIndex: 'language',
      key: 'language',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Select
              value={editedQuestion.language}
              onChange={(value) => handleInputChange(value, 'language')}
              style={{ width: 150 }}
            >
              <Option value="English">English</Option>
              <Option value="Sinhala">Sinhala</Option>
            </Select>
          );
        }
        return text;
      },
    },
    {
      title: 'Survey Type',
      dataIndex: 'surveyType',
      key: 'surveyType',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Select
              value={editedQuestion.surveyType}
              onChange={(value) => handleInputChange(value, 'surveyType')}
              style={{ width: 150 }}
            >
              <Option value="CLA">CLA</Option>
              <Option value="GMA">GMA</Option>
              <Option value="ALA">ALA</Option>
            </Select>
          );
        }
        return text;
      },
    },
    {
      title: 'Question Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Select
              value={editedQuestion.type}
              onChange={(value) => handleInputChange(value, 'type')}
              style={{ width: 150 }}
            >
              <Option value="MULTIPLE_CHOICE">Multiple Choice</Option>
              <Option value="TEXT">Text</Option>
            </Select>
          );
        }
        return text;
      },
    },
    {
      title: 'Action Controls',
      key: 'actions',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Space>
              <Button type="primary" onClick={handleSave} loading={loading}>
                Save
              </Button>
            </Space>
          );
        }
        return (
          <Space>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
              style={{ backgroundColor: '#3B7DDE' }}
            >
              Edit
            </Button>
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record.id)}
              loading={loading}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleLanguageChange = (checked) => {
    setLanguage(checked ? 'SIN' : 'ENG');
  };

  return (
    <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
      <Title strong>Questions Editor</Title>
      <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
        Create And Modify New Questions
      </Title>

      {newQuestionForm}

      <Space style={{ marginBottom: '10px' }}>
        <Text style={{ fontSize: '16px' }}>English</Text>
        <Switch
          checked={language === 'SIN'}
          onChange={handleLanguageChange}
          style={{ backgroundColor: '#3B7DDE' }}
        />
        <Text style={{ fontSize: '16px' }}>Sinhala</Text>
      </Space>

      <div style={{
        marginBottom: '20px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: '10px',
        padding: '20px',
      }}>
        <Table
          dataSource={questions}
          columns={columns}
          pagination={false}
          loading={loading}
          rowKey="id"
          style={{ marginTop: '10px', borderRadius: '10px' }}
          components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
        />
      </div>
    </div>
  );
};

export default Questions;