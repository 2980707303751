const emailTemplate2 = `
Good Day! 
 
Dear [Evauator], 
 
Greetings from CLA Great Managers! 
 
[Company] has registered to participate in CLA Agile Leadership Awards 2024 in Sri Lanka, powered 
by Colombo Leadership Academy & Business Agility Tune Up.
 
To understand the Leadership Effectiveness Index of the nominated leaders from [Company], CLA is conducting a survey on the direct reports & peers of the nominated Leaders. As respondents for the nominated leaders, you are requested to take a short survey. This survey consists of [NumberOfQuestions] questions and you will be rating the nominated leader on a scale of 1 to 5 on each of the statement. The survey will require 10 - 15 minutes of your time. 
 
You will be evaluating [Evaluatee]
 
Your individual responses are confidential. The aggregate results will be used for 
benchmarking the [Company] nominated Leader's leadership index with other 
participating organization managers. 
 
Please use the below link to take the assessment. The survey will be open from [FromDate] to [ToDate]. 
 
[URL]
 
Please reach out to survey@greatmanagers.org / 072-0865001 (Nilakshi) for any clarifications. 
 
Best Regards, 
CLA Great Managers 
`;

export default emailTemplate2;